'use client';
import Link from 'next/link';
import { motion } from 'framer-motion';
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Button } from "@heroui/react";
import getWhatsAppUrl from '@/app/Utils/getWhatsAppUrl';
interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {

  // WhatsApp handle
  const prefilledMessage =
    "Salut, Je voudrai avoir de l'aide ou plus de dé tails concernant CasaKonnect.";
  const handleContactUs = () => {
    let whatsAppUrl;
    try {
      whatsAppUrl = getWhatsAppUrl(`+243990525056`, prefilledMessage);
      window.open(whatsAppUrl, '_blank');
    } catch (error) {
      console.error('Error calling getWhatsAppUrl:', error);
    }
  };
  return (
    <motion.footer
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      className={`bg-black text-white py-16 ${className}`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h5 className="uppercase mb-4 font-bold">CasaKonnect</h5>
            <ul className="list-none mb-0">
              <li className="pb-4">
                <Link href="/about" className="text-white hover:text-gray-300">
                  À Propos de nous
                </Link>
              </li>
              <li className="pb-4">
                <Link
                  href="/how-it-works"
                  className="text-white hover:text-gray-300"
                >
                  Comment ça marche?
                </Link>
              </li>
              <li className="pb-4">
                <Link
                  href="/pricing"
                  className="text-white hover:text-gray-300"
                >
                  Tarification / Prix
                </Link>
              </li>

              <li className="pb-4">
                <Link
                  href="/contact-us"
                  className="text-white hover:text-gray-300"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <ul className="list-none mb-0">
              <li className="pb-4">
                <Link
                  href="/for-estate-agents"
                  className="text-white hover:text-gray-300"
                >
                  Pour Les Agents Immobiliers
                </Link>
              </li>
              <li className="pb-4">
                <Link
                  href="/for-service-providers"
                  className="text-white hover:text-gray-300"
                >
                  Pour Les Prestataires de Services
                </Link>
              </li>
              <li className="pb-4">
                <Link
                  href="/for-car-dealers"
                  className="text-white hover:text-gray-300"
                >
                  Pour Les Concessionnaires Automobiles
                </Link>
              </li>
            </ul>
          </div> */}

          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <ul className="list-none mb-0">
              <li className="pb-4">
                <Link
                  href="/products-listing?productCategory=&location="
                  className="text-white hover:text-gray-300"
                >
                  Tous les produits
                </Link>
              </li>
              {/* <li className="pb-4">
                <Link
                  href="/cars-listing"
                  className="text-white hover:text-gray-300"
                >
                  Toutes les voitures
                </Link>
              </li> */}
              {/* <li className="pb-4">
                <Link
                  href="/properties-listing"
                  className="text-white hover:text-gray-300"
                >
                  Toutes les propriétés
                </Link>
              </li> */}
              {/* <li className="pb-4">
                <Link
                  href="/services-listing"
                  className="text-white hover:text-gray-300"
                >
                  Tous les services
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <div className="flex gap-2">
              <a
                href="https://www.linkedin.com/company/casakonnect/"
                target="_blank"
                className="transition-transform transform hover:scale-125 hover:text-blue-700"
              >
                <FaLinkedin size={25} className="text-text" />
              </a>
              <a
                href="https://www.facebook.com/people/CasaKonnectio/61559978793935/"
                target="_blank"
                className="transition-transform transform hover:scale-125 hover:text-blue-600"
              >
                <FaFacebook size={25} />
              </a>
              <a
                href="https://www.instagram.com/casakonnect.io/"
                target="_blank"
                className="transition-transform transform hover:scale-125 hover:text-pink-600"
              >
                <FaInstagram size={25} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCncZy0Lz_5TlmU2Wfn6eXRQ"
                target="_blank"
                className="transition-transform transform hover:scale-125 hover:text-red-600"
              >
                <FaYoutube size={25} />
              </a>
            </div>
            <ul className="list-none mb-0">
              <li className="my-4">
                <Link
                  href="/privacy-portal/terms-and-conditions"
                  className="text-white hover:text-gray-300"
                >
                  Conditions d'utilisation
                </Link>
              </li>
              <li className="my-4">
                <Link href="/safety" className="text-white hover:text-gray-300">
                  Sécurité
                </Link>
              </li>
              <li className="my-4">
                <Button
                  size='md'
                  className="flex items-center font-bold text-white bg-gray-900 p-1 rounded"
                  onClick={handleContactUs}
                >
                  <FaWhatsapp size={30} className="mr- text-green-700" />
                  +243 990 525056
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center text-gray-400 pt-4">
          © {new Date().getFullYear()} CasaKonnect. All rights reserved.
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
