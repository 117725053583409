'use client';
import Avatar from '@/app/components/base/Avatar';
import NextUIButton from '@/app/components/base/NextUIButton';
import useLoginModal from '@/app/hooks/useLoginModal';
import UseRegisterModal from '@/app/hooks/useRegisterModal';
import { logout } from '@/app/providers/slices/AuthSlice';
import { AppDispatch } from '@/app/providers/store';
import { store } from '@/redux/store';
import { Button } from "@heroui/react";
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaCar, FaHome, FaTimes, FaToolbox, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import MenuItem from './MenuItem';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const mobileMenuItems = [
  {
    id: 1,
    label: 'Ajouter Produit',
    link: '/create-product',
    icon: <FontAwesomeIcon icon={faCartShopping} className="mr-2" />,
  },
  // {
  //   id: 2,
  //   label: 'Ajouter Propriété',
  //   link: '/create-property',
  //   icon: <FaHome />,
  // },
  // {
  //   id: 2,
  //   label: 'Ajouter Service',
  //   link: '/create-service',
  //   icon: <FaToolbox />,
  // },
  // {
  //   id: 4,
  //   label: 'Ajouter Véhicule',
  //   link: '/create-vehicle',
  //   icon: <FaCar />,
  // },
];

const extraLinks = [
  {
    id: 1,
    label: 'Contactez-nous',
    link: '/contact-us',
  },
  {
    id: 2,
    label: 'Apropos de CasaKonnect ',
    link: '/about',
  },
  {
    id: 3,
    label: 'Privacy portal',
    link: '/privacy-portal',
  },
  {
    id: 4,
    label: "conditions d'utilisation",
    link: '/privacy-portal/terms-and-conditions',
  },
];

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const router = useRouter();

  const registerModal = UseRegisterModal();
  const loginModal = useLoginModal();
  const dispatch = useDispatch<AppDispatch>();

  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleModal = useCallback(() => {
    setIsOpen((value) => !value);
    setShowMobileMenu(!showMobileMenu);
  }, [showMobileMenu]);

  const onSubmitLogout = async () => {
    dispatch(logout());
    window.location.href = '/';
  };

  useEffect(() => {
    // Load user data from local storage on component mount
    const userData = store.getState()?.authSlice?.user ?? {};
    setUser(userData);
    setIsAuthenticated(userData && Object.keys(userData).length > 0);
  }, []);

  function handleRedirect(url: string, openModal?: () => void) {
    const protectedRoutes = ['/create-property', '/create-service', '/create-vehicle'];
    if (!isAuthenticated && (url === '/account' || protectedRoutes.includes(url))) {
      // If the user is not authenticated and clicked on "Mon Compte / account" or a protected route
      loginModal.onOpen(); // Open the login modal
    } else {
      setShowMobileMenu(false); // Close the mobile menu
    }
    if (openModal) {
      openModal(); // If a modal function is provided, open the modal
    } else {
      router.push(url); // otherwise, navigate to the specified URL
    }
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      className="relative">
      <motion.div className="flex flex-row items-center gap-3">
        {isAuthenticated ? (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            className="hidden  md:block text-sm font-semibold py-3 px-4 rounded-full hover:bg-neutral-100 transition cursor-pointer">
            Welcome, {user && user.username}
          </motion.div>
        ) : (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            className="hidden md:block p-4  md:py-1 md:px-2">
            <NextUIButton
              label="S'inscrire"
              color="default"
              radius="lg"
              variant="shadow"
              onClick={registerModal.onOpen}
            />
          </motion.div>
        )}
        <div
          onClick={toggleModal}
          className="p-4 md:py-1 md:px-2 border-[1px] border-neutral-200 flex flex-row items-center gap-3 rounded-full cursor-pointer hover:shadow-md transition"
        >
          <AiOutlineMenu />
          <div className="hidden md:block">
            <Avatar />
          </div>
        </div>
      </motion.div>

      {showMobileMenu && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.75 }}
          className="lg:hidden fixed bg-black top-0 left-0 right-0 bottom-0">
          <div className="min-h-screen flex flex-col gap-5 py-10 px-10">
            <span
              className="self-end text-[28px] font-light text-white mb-10"
              onClick={() => setShowMobileMenu(false)}
            >
              <FaTimes />
            </span>
            <motion.ul
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
              className="flex flex-col gap-14">
              {isAuthenticated && (
                <li
                  key={1}
                  className="text-[24px] text-white font-medium flex items-center gap-5"
                  onClick={() => handleRedirect('/account')}
                >
                  <FaUser className="mr-2" />
                  <span>Mon Compte</span>
                </li>
              )}
              {mobileMenuItems.map((menu) => (
                <li
                  key={menu.id}
                  className="text-[24px] text-white font-medium flex items-center gap-5"
                  onClick={() => handleRedirect(menu.link)}
                >
                  {menu.icon && <span className="mr-2">{menu.icon}</span>}
                  <span>{menu.label}</span>
                </li>
              ))}
              {/* Render login and signup buttons for unauthenticated users */}
              {!isAuthenticated && (
                <>
                  <li
                    className="text-[24px] text-white font-medium flex items-center gap-5"
                    onClick={() => handleRedirect('', loginModal.onOpen)}
                  >
                    <FaUser className="mr-2" />
                    <span>Se connecter</span>
                  </li>
                  <li
                    className="text-[24px] text-white font-medium flex items-center gap-5"
                    onClick={() => handleRedirect('', registerModal.onOpen)}
                  >
                    <FaUser className="mr-2" />
                    <span>S'inscrire</span>
                  </li>
                </>
              )}
            </motion.ul>
            {/* Render the Deconnexion button only when authenticated */}
            {isAuthenticated && (
              <Button
                className="mt-10 border-2 border-black"
                style={btnStyle}
                size="lg"
                color="primary"
                onClick={onSubmitLogout}
              >
                Déconnexion
              </Button>
            )}

            <motion.ul
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
              className="mt-auto flex gap-2 text-white flex-wrap">
              {extraLinks.map((link) => (
                <li
                  key={link.id}
                  className="text-[12px] text-white font-medium"
                >
                  <Link href={link.link}>{link.label}</Link>
                </li>
              ))}
            </motion.ul>
          </div>
        </motion.div>
      )}
      {isOpen && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.75 }}
          className="hidden lg:block fixed  bg-[rgba(0,0,0,0.5)] overflow-hidden right-0 top-0 left-0 bottom-0 text-sm"
          onClick={toggleModal}
        >
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            className="flex justify-end mt-20 mr-10">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
              className="flex flex-col cursor-pointer rounded-xl shadow-md w-[250px] bg-white">
              {isAuthenticated && (
                <>
                  <MenuItem
                    label="Mon Compte"
                    href="/account"
                    replace={true}
                    scroll={true}
                    prefetch={true}
                  />
                  <MenuItem onClick={onSubmitLogout} label={'Déconnexion'} />
                </>
              )}
              {!user.email && (
                <>
                  <MenuItem label="Se connecter" onClick={loginModal.onOpen} />
                  <MenuItem
                    onClick={registerModal.onOpen}
                    label={"S'inscrire"}
                  />
                </>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default UserMenu;

const btnStyle = { backgroundColor: 'white', color: 'black', marginLeft: 14 };
